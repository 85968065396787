<template>
    <div>
        <app-table
            :columns="columns"
            :actions="tableActions"
            url="payments"
            ref="table"
        ></app-table>
    </div>
</template>

<script>
    import AppTable from '@/components/Table.vue';

    export default {
        components: {
            AppTable,
        },
        data() {
            return {
                columns: [                    
                    {
                        text: 'Date',
                        value: 'date',
                    },
                    {
                        text: 'Type',
                        value: 'type',
                    },
                    {
                        text: 'Tax Rate',
                        value: 'tax_rate.title',
                    },
                    {
                        text: 'Amount',
                        value: 'amount_for_human',
                        align: 'end',
                    },
                ],
                tableActions: [],
            }
        },
    }
</script>
